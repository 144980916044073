import React, {useContext, useEffect, useState} from 'react'
import {ContentContext} from "../App";
import {stateToHTML} from "draft-js-export-html";
import {convertFromRaw} from "draft-js";

const CookiesPolicy = () => {
    const { content } = useContext(ContentContext);

    const [main, setMain] = useState(null);

    useEffect(() => {
        if(content) {
            setMain(stateToHTML((convertFromRaw(JSON.parse(content.cookies_policy)))))
        }
    }, [content]);

    return <main className="pageText pageText--terms section" dangerouslySetInnerHTML={{__html: main}}>
{/*        <h1 className="pageText__header">*/}
{/*            Polityka plików cookies*/}
{/*        </h1>*/}
{/*        <h2 className="pageText__subheader">*/}
{/*            § 1<br/>*/}
{/*        </h2>*/}
{/*        <p>*/}
{/*            1. Niniejsza Polityka określa zasady przechowywania przez Administratora informacji oraz dostępu Administratora do informacji już przechowywanej na Urządzeniach Klienta w formie plików Cookies.*/}
{/*<br/>*/}
{/*            2. Wszelkie pojęcia, zdefiniowane w Polityce prywatności, zachowują swoje znaczenie także na gruncie niniejszej Polityki Cookies. Nadto, następującym pojęciom nadaje się poniższe znaczenie:*/}
{/*            <div className="left-40">*/}
{/*                a) Cookies - oznacza dane informatyczne, w szczególności niewielkie pliki tekstowe, zapisywane i przechowywane na urządzeniach za pośrednictwem których Klient korzysta ze stron internetowych Serwisu. Cookies zazwyczaj zawierają nazwę strony internetowej, z której pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer.*/}
{/*                <br/>*/}
{/*                b) Cookies Własne - oznacza Cookies zamieszczane przez Administratora, związane ze świadczeniem usług droga elektroniczną przez Adminisratora za pośrednictwem Serwisu.*/}
{/*                <br/>*/}
{/*                c) Cookies Zewnętrzne - oznacza Cookies zamieszczane przez osoby trzecie, za pośrednictwem strony internetowej Serwisu.*/}
{/*                <br/>*/}
{/*                d) Polityka – niniejszej Polityka plików Cookies, stanowiąca załącznik nr 1 do Polityki prywatności.*/}
{/*                <br/>*/}
{/*                e) Urządzenie - oznacza elektroniczne urządzenie za pośrednictwem, którego Klient uzyskuje dostęp do Serwisu.*/}
{/*            </div>*/}
{/*        </p>*/}

{/*        <h2 className="pageText__subheader">*/}
{/*            § 2<br/>*/}
{/*        </h2>*/}
{/*        <p>*/}
{/*            1. Administrator za pomocą plików Cookies przechowuje na Urządzeniu Klienta informacje lub uzyskuje dostęp do informacji już przechowywanej – na zasadach określonych w niniejszej Polityce.*/}
{/*<br/>*/}
{/*            2. Administrator wykorzystuje następujące typy plików Cookies:*/}
{/*            <div className="left-40">*/}
{/*                a) Cookies sesyjne (session cookies): są przechowywane na Urządzeniu Klienta i pozostają tam do momentu zakończenia sesji danej przeglądarki. Zapisane informacje są wówczas trwale usuwane z pamięci Urządzenia*/}
{/*                <br/>*/}
{/*                b) Cookies trwałe (persistent cookies): są przechowywane na Urządzeniu Klienta i pozostają tam przez okres wskazany w parametrach pliku lub do momentu ich skasowania. Zakończenie sesji danej przeglądarki lub wyłączenie Urządzenia nie powoduje usunięcia tego rodzaju Cookies z Urządzenia.*/}
{/*            </div>*/}
{/*            3. Korzystanie przez Administratora z plików Cookies nie powoduje zmian konfiguracyjnych w Urządzeniu Klienta i oprogramowaniu zainstalowanym w tym Urządzeniu.*/}
{/*        </p>*/}

{/*        <h2 className="pageText__subheader">*/}
{/*            § 3<br/>*/}
{/*        </h2>*/}
{/*        <p>*/}
{/*            1. Administrator wykorzystuje Cookies Własne w celu dostosowania zawartości Serwisu do preferencji i potrzeb Użytkownika, w szczególności przy uwzględnieniu rodzaju Urządzenia za pomocą którego Użytkownik korzysta z Serwisu. Tego rodzaju Cookies obejmują:*/}
{/*            <div className="left-40">*/}
{/*                a) „niezbędne” pliki cookies, umożliwiające korzystanie z usług dostępnych w ramach Serwisu, np. uwierzytelniające pliki cookies wykorzystywane do usług wymagających uwierzytelniania w ramach Serwisu;*/}
{/*                <br/>*/}
{/*                b) pliki cookies służące do zapewnienia bezpieczeństwa, na przykład wykorzystywane do wykrywania nadużyć w zakresie uwierzytelniania w ramach Serwisu;*/}
{/*                <br/>*/}
{/*                c) „wydajnościowe” pliki cookies, umożliwiające zbieranie informacji o sposobie korzystania ze stron internetowych Serwisu;*/}
{/*                <br/>*/}
{/*                d) „funkcjonalne” pliki cookies, umożliwiające „zapamiętanie” wybranych przez Użytkownika ustawień i personalizację interfejsu Użytkownika, np. w zakresie wybranego języka lub regionu, z którego pochodzi Użytkownik, rozmiaru czcionki, wyglądu strony internetowej itp.;*/}
{/*                <br/>*/}
{/*                e) „reklamowe” pliki cookies, umożliwiające dostarczanie Użytkownikom treści reklamowych bardziej dostosowanych do ich zainteresowań.*/}
{/*            </div>*/}
{/*            2. Administrator wykorzystuje Cookies Zewnętrzne w celu tworzenia zbiorczych statystyk i analiz, służących monitorowaniu sposobu korzystania ze Serwisu przez Klientów – co umożliwia jak najlepsze dostosowanie go do potrzeb Klientów. W tym zakresie Administrator korzysta z usług:*/}
{/*            <div className="left-40">*/}
{/*                Google Analytics – w ramach której w chwili otworzenia Serwisu następuje odczytanie, napisanie i wysłanie na zabezpieczony serwer Google informacji o pochodzeniu użytkowników identyfikowanych na podstawie IP. Szczegółowe informacje w tym zakresie dostępne są na witrynie Google (https://policies.google.com/technologies/types?hl=pl).*/}
{/*            </div>*/}
{/*            3. W związku z korzystaniem z plików cookies, Administrator gromadzi wyłącznie dane statystyczne, związane z historią przeglądania. Inne dane (jak np. imię, nazwisko, adres itd.) nie są w żaden sposób utrwalane.*/}
{/*        </p>*/}

{/*        <h2 className="pageText__subheader">*/}
{/*            § 4<br/>*/}
{/*        </h2>*/}
{/*        <p>*/}
{/*            1. Klient ma możliwość ograniczenia lub wyłączenia dostępu plików Cookies do swojego Urządzenia w ustawieniach przeglądarki internetowej lub poprzez konfigurację usługi - w szczególności w taki sposób, aby blokować automatyczną obsługę plików Cookies bądź informować o każdorazowym zamieszczeniu Cookies na Urządzeniu Klienta. Opis niezbędnych w tym celu czynności można odnaleźć na witrynach producentów, np:*/}
{/*            <div className="left-40">*/}
{/*                Mozilla Firefox:  https://support.mozilla.org/pl/kb/W%C5%82%C4%85czanie%20i%20wy%C5%82%C4%85czanie%20obs%C5%82ugi%20ciasteczek*/}
{/*                <br/>*/}
{/*                Google Chrome: https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=pl*/}
{/*                <br/>*/}
{/*                Microsoft Edge: https://privacy.microsoft.com/pl-pl/windows-10-microsoft-edge-and-privacy*/}
{/*            </div>*/}
{/*            2. Klient może w każdej chwili usunąć pliki Cookies.*/}
{/*            <br/>*/}
{/*            3. Ograniczenie stosowania plików Cookies, może wpłynąć na niektóre funkcjonalności dostępne na stronie internetowej Serwisu.*/}
{/*        </p>*/}
    </main>
}

export default CookiesPolicy;


















