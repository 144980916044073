import React from 'react'
import DraftLoader from "../components/Loader";

const LoadingPage = () => {
    return <main className="loadingPage loadingPage--dark">
        <DraftLoader />
    </main>
}

export default LoadingPage;
